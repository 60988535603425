<template>
<div class="signup employer">
  <div class="signup-body">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class=" col-12 col-md-8 col-lg-6 pt-5 pt-lg-0 pr-3 pr-md-5 align-self-center">
          <h3 class="mb-2 signup-header">Contact</h3>
          <p class="mb-4">Need to get in touch with the team? Drop us a line.</p>
          <div class="row mb-4">
            <div class="col-1 col-md-1">
              <i class="el-icon-message h1 orange-text"></i>  
            </div>
            <div class="col-9 ml-3">
              <h5 class="semi-bold mb-1">Email us</h5>
               <span class="p" id="obfuscated"><canvas style="width: 155px; height: 24px; cursor: pointer;"></canvas></span> 
            </div>  
          </div>
          <div class="row">
            <div class="col-1 col-md-1">
              <i class="el-icon-location-information h1 orange-text"></i>  
            </div>
            <div class="col-9 ml-3">
              <h5 class="semi-bold mb-1">CitronWorks, Inc</h5>
              <p class="mb-0">11693 San Vicente Blvd, Ste #182</p>
              <p>Los Angeles, CA 90049 USA</p>
            </div>  
          </div>
          <img src="/images/contact.png" style="max-width: 400px; width: 100%;"/>
          
        </div>
        <div class="content mt-3 col-md-8 col-lg-6 p-0">
          <hr>
          <div class="form">
            <contact-form></contact-form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
  import ContactForm from '~/components/ContactForm'
  import EmailObfuscate from 'email-obfuscate';

  export default {
    components: {
      ContactForm,
    },  
    mounted () {
      const el = document.getElementById('obfuscated')
      EmailObfuscate(el, {
        // Email construct: name@domain.tld
        name: 'team',
        domain: 'citronworks',
        tld: 'com',
        // Alternate Text
        altText: 'Email Address'
      });
    },
  }
</script>

<style scoped>

@media (max-width: 992px) {
  .pt-5 {
    padding-top: 1rem !important;
  }
  img {
    /*display: block;
    margin: 0 auto;*/
    display: none;
  }
}
</style>